import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="page-content" style={{marginTop: "4rem", marginBottom:"3rem"}}>
      <img style={{float: "left", marginRight: "2rem"}} src="https://media.giphy.com/media/9J7tdYltWyXIY/source.gif" alt="not found" />
      <h1 style={{textAlign:"left"}}>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
    <div style={{clear: "both"}}/>
  </Layout>
)

export default NotFoundPage
